import $ from 'jquery'

function Footer (el) {
  const $el = $(el)
  const $subscribe = $el.find('.subscribe')
  const $subscribeButton = $subscribe.find('.subscribe__button')
  const $footerWidgetToggle = $el.find('[data-toggle-widget]')

  // Newsletter block
  $subscribeButton.on('click', function () {
    $subscribe.addClass('js-form-visible')
  })

  $footerWidgetToggle.on('click', function () {
    $(this).parent().toggleClass('js-widget-visible')
  })
}

module.exports = Footer
