import $ from 'jquery'

function ShowMore(el) {
  const $btn = $(el).find('[data-show-more]');
  const $list = $(el).find($btn.data('show-more'));
  const $block = $list.parent();
  const cssActiveClass = 'active';
  const btnText = $btn.text();
  const btnTextActive = $btn.data('text-active');

  let height;
  let heightActive;

  init();
  onResize();
  $btn.on('click', btnToggle);

  function btnToggle() {
    $block.toggleClass(cssActiveClass);

    heightToggle();

    if ($block.hasClass(cssActiveClass)) {
      $btn.text(btnTextActive);
    } else {
      $btn.text(btnText);
    }
  }

  function heightToggle() {
    if ($block.hasClass(cssActiveClass)) {
      $block.height(heightActive);
    } else {
      $block.height(height);
    }
  }

  function heightSet() {
    const firstChildMarginBottom = parseInt($($list[0].children[0]).css('marginBottom'));

    height = $list[0].children[0].offsetHeight + firstChildMarginBottom;
    heightActive = $list[0].offsetHeight;
  }

  function onResize() {
    let resizeTimer;

    $(window).on('resize', function(e) {

      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {

        init();
      }, 250);

    });
  }

  function init() {
    heightSet();

    const isActive = height !== heightActive;
    height = isActive ? height : 'auto';

    $block.toggleClass('disabled', !isActive);

    heightToggle();
  }

}

module.exports = ShowMore;
