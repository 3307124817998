import $ from 'jquery'

const membershipRegister = $domNode => {
    const $root = $('html, body');
    const $link = $($domNode).find('#membership-register__link');
    const $hero = $($domNode.parentNode).find('#membership-hero');

    if(!$link.length || !$hero.length) return;

    /**
     * Smooth scroll to games section on click
     */
    $link.click(e => {
        e.preventDefault();

        $root.animate({
            scrollTop: $hero.offset().top
        }, 1600);
    });
}

module.exports = membershipRegister
