import Modal from './../modal/modal.js'

class GlobalSignup {
  constructor (domNode) {
    this.domNode = domNode
    this.hash = '#signup'
    this.init()
  }

  onClose = () => {
    // Remove hash
    window.history.pushState('', document.title, window.location.pathname)
  }

  init = () => {
    const modal = Modal(this.domNode, { onClose: this.onClose })

    if (window.location.hash === this.hash && !modal.open) {
      modal.toggleModal()
    } else {
      // Listen to show signup modal when location.hash is #signup
      window.onhashchange = this.init
    }
  }
}

module.exports = domNode => new GlobalSignup(domNode)
