const $ = require('jquery')

function FourUpCards (el) {
  var $el = $(el)

  $el.find('.four-up-cards__card').on('click', function () {
    $(this).toggleClass('js-card-explain')
  })
}

module.exports = FourUpCards
