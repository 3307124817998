const $ = require('jquery');
var throttle = require('lodash.throttle');

function validateEmail(email) {
    var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(email);
}

function alphaResize(){
    if(window.matchMedia("(min-width: 37.5em)").matches)
    {
      window.hoffsetl = -18;
      window.hoffsetr = -18;
    }

    if(window.matchMedia("(min-width: 50em)").matches)
    {
      window.hoffsetl = -72;
      window.hoffsetr = -72;
    }  

    if(window.matchMedia("(min-width: 80em)").matches)
    {
      window.hoffsetl = -90;
      window.hoffsetr = -72;
    }  

    alphaScroll();   
}

function alphaScroll(){
    var pos = $(window).scrollTop();

    if(pos > $('.form.hero').offset().top - $(window).height() && !$('.rocket').hasClass('active'))
    {
      $('.rocket').addClass('active');
    }

    $('.hero__image--left').css('top', window.hoffsetl - (pos * .5));
    $('.hero__image--right').css('top', window.hoffsetr - (pos * .3));

    if(pos > $('.alpha-about').offset().top - ($(window).height() * .5) && !$('.cable-guy').hasClass('active')){
      $('.cable-guy').addClass('active');
    }
}

function AlphaForm(el) {
  var $el = $(el);
  var pos = 0;
  window.hoffsetl = 0;
  window.hoffsetr = 0;

  $el.find('.button--primary').on('click', function(e) {
    $el.find('#form-errors').slideUp();
    
    var errors = [];

    if($.trim($el.find('#265632_5970pi_265632_5970').val()) == '')
    {
      errors.push('Please enter your first name');
    }

    if($.trim($el.find('#265632_5972pi_265632_5972').val()) == '')
    {
      errors.push('Please enter your last name');
    }

    if($.trim($el.find('#265632_5974pi_265632_5974').val()) == '' || !validateEmail($.trim($el.find('#265632_5974pi_265632_5974').val())))
    {
      errors.push('Please enter a valid email address');
    }

    if( errors.length > 0 ) {
      e.preventDefault();

      $el.find('#form-errors').html(errors.join('<br>')).slideDown();

      return false;
    }
  });

  $(window).on('resize', alphaResize);
  alphaResize();

  $(window).on('scroll', alphaScroll);

  
  setTimeout(function(){
    $('.hero__image').addClass('active');
  }, 500);

}

module.exports = AlphaForm;