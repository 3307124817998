var $ = require('jquery');

function Slideout(el) {
    var $el = $(el),
        $slideoutWidgets = $el.find('.slideout__widget'),
        $slideoutWidgetToggle = $el.find('[data-widget-toggle]'),
        $slideoutToggle = $el.find('.slideout__toggle');

    $slideoutWidgetToggle.on('click', function() {
        $(this).parent().toggleClass('js-slideout-visible').siblings().removeClass('js-slideout-visible');
    });

    $slideoutToggle.on('click', function() {
        $('html').removeClass('js-slideout-active');
    });
}

module.exports = Slideout;