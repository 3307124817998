const $ = require('jquery');
const matchHeight = require('jquery-match-height');

function PricingData(el) {

    let t = this;

    t.$el = $(el);
    t.$embed = t.$el.find('.pricing-modal__embed');

    $(el).on('click', '.show-modal', t.click.bind(t));
    $(el).on('click', '.toggle-info', t.toggle.bind(t));
    t.$el.on('click', '.pricing-modal__overlay, .pricing-modal__close', t.close.bind(t));

}

PricingData.prototype.close = function () {
    let t = this;
    t.$el.removeClass('js-video-modal-active');
    $('body').removeClass('video-modal-view');
};

PricingData.prototype.click = function (e) {
    let t = this;
    e.preventDefault();
    t.$el.addClass('js-video-modal-active');
    $('body').addClass('video-modal-view');

};

PricingData.prototype.toggle = function (e) {
    let button = $(e.target);
    e.preventDefault();

    button.closest('.pricing-data__col').toggleClass('show-details');
}

module.exports = PricingData;