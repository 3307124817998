var $ = require('jquery');
var Flickity = require('flickity');

function ThreeUpCarousel(el) {
  var $el = $(el);

  var flkty = new Flickity( el, {
    imagesLoaded: true,
    cellAlign: 'left',
    pageDots: true,
    prevNextButtons: false,
    contain: true,
    groupCells: true
  });

}

module.exports = ThreeUpCarousel;
