import Modal from './../modal/modal.js'

class PardotForm {
    constructor (domNode) {
        this.domNode = domNode;
        this.delayTime = domNode.getAttribute('data-pardot-popup-delay-time') || 0;
        this.init();

    }
    init = () => {
        const modal = Modal(this.domNode, {delayMS: 30000});

        setTimeout(modal.toggleModal, this.delayTime);
    }
}

module.exports = domNode => new PardotForm(domNode);
