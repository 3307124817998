import _ from 'lodash'
import $ from 'jquery'
import Cookies from 'js-cookie'
import autobind from 'class-autobind'

const BASE_HOSTNAME = process.env.BASE_HOSTNAME || 'legendsoflearning.com';
const API_ENDPOINT = process.env.API_ENDPOINT || 'https://app.legendsoflearning.com/api/gameplayer/graphql';
const REDIRECT_URL = process.env.SIGNUP_REDIRECT_URL || 'https://teachers.legendsoflearning.com/';
const SLUG = window.location.pathname.replace(/\//g, '');
const PASSWORD_MIN_LENGTH = 6;
const TOKEN_KEY = 'lol_token';

const ERROR_MESSAGES = {
  duplicate: `
    Looks like we already have an account associated with this email&nbsp;
    address! <a href="${REDIRECT_URL}/reset-password" target="_blank">
    Click here</a> to reset your password.
    `,
  general: 'Sorry, something went wrong.',
  incomplete: '*Please complete all of the required fields',
  passwordMismatch: 'Please make sure your passwords match!',
  passwordMin: `We need your password to be at least ${PASSWORD_MIN_LENGTH} characters!`,
  terms: 'You must agree to the terms and conditions.'
};

const MUTATIONS = {
  authenticate: input => `
    mutation {
      authenticateTeacher(input: {
        email: "${input.email}",
        password: "${input.password}"
      }), { jwtToken }
    }
  `,
  register: input => `
    mutation {
      registerTeacher(input: {
        email: "${input.email}",
        givenName: "${input.firstName}",
        familyName: "${input.lastName}",
        password: "${input.password}",
        passwordConfirmation: "${input.passwordConfirm}",
        referralCode: "${input.referralCode}",
      }), {
        teacher { email givenName familyName referralCode teacherId teacherInfo { id } }
      }
    }
  `,
  update: (input, teacher) => `
    mutation {
      updateTeacherInfo(input: {
        id: "${teacher.teacherInfo.id}",
        teacherInfoPatch: {
        gradeLevel: "${input.gradeLevel}",
        gradeLevelElementary: ${input.gradeLevelElementary},
        gradeLevelMiddle: ${input.gradeLevelMiddle},
        schoolName: "${input.schoolName}",
        schoolDistrict: "${input.schoolDistrict}",
        phoneNumber: "${input.phoneNumber}",
        referralSource: "${input.referralSource}",
        signupFormVariant: "wpform",
        },
      }), 
      {
        teacherInfo { schoolName schoolDistrict phoneNumber referralSource gradeLevel gradeLevelElementary gradeLevelMiddle signupFormVariant }
      }
    }
    `,
}

class SignupForm {
  /**
   * Authenticate new user by fetching JWT Token
   * via POST to GraphQL endpoint
   * @returns {Promise}
   */
  authenticateNewUser = registrationResponse =>
    $.post({
      url: API_ENDPOINT,
      data: JSON.stringify({
        query: MUTATIONS.authenticate(this.getInputValues())
      }),
      contentType: 'application/json'
    })
      .then(response => {
        this.setAuthToken(response)

        return registrationResponse
      })


  /**
   * Select current values from form inputs
   * @returns {object} Fields by this.fields key and current DOM value
   */
  getInputValues = () =>
    _.reduce(this.fields, (m, v, k) => ({
      ...m,
      [k]: ($(v).attr('type') === 'checkbox' ? $(v).is(':checked') : this.$form.find(v).val())
    }), {});
  /**
   * Form submit handler
   * @returns {void}
   */
  handleFormSubmit = e => {
    e.preventDefault();
    this.toggleErrorMessage();
    const inputs = this.getInputValues();

    return this.validateInputs(inputs)
      && this.postRegistration(inputs)
        .then(this.parseRegistrationResponse)
        .then(this.authenticateNewUser)
        .then(({data}) => data && data.registerTeacher && this.updateTeacherInfo(data.registerTeacher.teacher))
        .done(this.redirectUser)
        .catch(console.error)
  }
  /**
   * POST Registration inputs to GraphQL endpoint
   * @param {object} inputs
   * @returns {Promise}
   */
  postRegistration = inputs =>
    $.post({
      url: API_ENDPOINT,
      data: JSON.stringify({
        query: MUTATIONS.register(inputs)
      }),
      contentType: 'application/json'
    })
  /**
   * Toggle error message node, hiding
   * node if no message is provided
   * @param {string} [] Message to be displayed
   * @returns {void}
   */
  toggleErrorMessage = message => {
    if (!message) {
      this.$errorMessage.hide()
        .find('.message')
        .empty()
    } else {
      this.$errorMessage
        .show()
        .find('.message')
        .append(message)
    }
  }

  constructor(domNode, args) {
    autobind(this)
    this.$form = $(domNode);

    this.fields = {
      email: '[name="signup-form__email"]',
      firstName: '[name="signup-form__first-name"]',
      lastName: '[name="signup-form__last-name"]',
      password: '[name="signup-form__password"]',
      passwordConfirm: '[name="signup-form__confirm-password"]',
      referralCode: '[name="signup-form__referral-code"]',
      schoolName: '[name="signup-form__school-name"]',
      schoolDistrict: '[name="signup-form__school-district"]',
      phoneNumber: '[name="signup-form__phone-number"]',
      referralSource: '[name="signup-form__referral-source"]',
      gradeLevel: '[name="signup-form__grade-level"]',
      gradeLevelElementary: '[name="signup-form__grade-level-elementary"]',
      gradeLevelMiddle: '[name="signup-form__grade-level-middle"]'
    };
    this.requiredFields = {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email'
    };
    this.$acceptTerms = this.$form.find('[name="signup-form__accept_terms"]');
    this.$errorMessage = this.$form.find('.signup-form__error-message');

    // Initialize:
    this.init();
  }

  /**
   * Initialize class instance
   * @returns {void}
   */
  init() {
    this.$form.on('submit', this.handleFormSubmit)
  }

  /**
   * Log registration event to Google Analytics via GTM
   * @returns {void}
   */
  logRegistrationEvent() {
    const event = {
      'event': 'GAEvent',
      'eventCategory': 'teacher',
      'eventAction': 'Register',
      'eventLabel': SLUG
    }

    if (window.dataLayer) {
      window.dataLayer.push(event)
    } else {
      console.error('Could not publish GA Event for registration', event)
    }
  }

  /**
   * Parse Registration POST response
   * @param {object} response
   * @returns {object|Error} data or error
   */
  parseRegistrationResponse(response) {
    if (!response.errors) {
      this.logRegistrationEvent();

      return response;
    } else {
      const error = response.errors[0].message

      if (error.indexOf('teacher_email_key') !== -1) {
        this.toggleErrorMessage(ERROR_MESSAGES.duplicate)
      } else {
        this.toggleErrorMessage(ERROR_MESSAGES.general)
      }

      throw new Error(error);
    }
  }

  /**
   * Validate password match and length,
   * invoking corresponding error message when invalid
   * @param {string} password
   * @param {string} passwordConfirm
   * @param {boolean} isValid
   */
  passwordsValidate(a, b) {
    if (a !== b) {
      this.toggleErrorMessage(ERROR_MESSAGES.passwordMismatch)
      return false
    } else if (PASSWORD_MIN_LENGTH > a.length) {
      this.toggleErrorMessage(ERROR_MESSAGES.passwordMin)
      return false
    }
    return true
  }

  updateTeacherInfo(teacher) {
    if (!teacher) return;

    return $.post({
      headers: {Authorization: `Bearer ${Cookies.get(TOKEN_KEY)}`},
      url: API_ENDPOINT,
      data: JSON.stringify({
        query: MUTATIONS.update(this.getInputValues(), teacher)
      }),
      contentType: 'application/json'
    });
  }

  setAuthToken({data, errors}) {
    if (!errors && data.authenticateTeacher) {
      const {jwtToken} = data.authenticateTeacher;

      return Cookies.set(TOKEN_KEY, jwtToken, {
        expires: 1,
        domain: BASE_HOSTNAME,
        path: '/'
      })

    } else {
      return this.toggleErrorMessage(errors[0].message);
    }
  }

  /**
   * Redirect user to application
   * @param {object} response
   * @returns {void}
   */
  redirectUser({data, errors}) {
    if (Cookies.get(TOKEN_KEY)) {
      window.location = REDIRECT_URL
    }
  }

  /**
   * Validate terms checkbox is checked,
   * invoking corresponding error message when unchecked
   * @param {JQuery} checkbox
   * @returns {boolean}
   */
  termsAccepted($checkbox) {
    const accepted = $checkbox.prop('checked')

    if (!accepted) {
      this.toggleErrorMessage(ERROR_MESSAGES.terms)
    }

    return accepted;
  }

  /**
   * Validate required (non-password) fields,
   * display error message when invalid
   * @param {object} inputs
   * @returns {boolean} if valid, returns true
   */
  validateRequiredFields(inputs) {
    let emptyFields = [];

    _.forEach(this.requiredFields, (value, key) => {
      if (!inputs[key]) {
        emptyFields.push(value);
      }

      this.$form.find(`${this.fields[key]}`).toggleClass('has-error', !inputs[key]);
    });

    emptyFields = _.join(emptyFields, ', ');

    if (emptyFields) {
      this.toggleErrorMessage(`${ERROR_MESSAGES.incomplete}: ${emptyFields}.`);
      return false;
    }

    return true;
  }

  /**
   * Validate all inputs
   * @param {object} inputs
   * @returns {boolean}
   */
  validateInputs(inputs) {
    const {password, passwordConfirm} = inputs;

    return this.validateRequiredFields(inputs)
      && this.passwordsValidate(password, passwordConfirm)
      && this.termsAccepted(this.$acceptTerms);
  }
}

module.exports = domNode => new SignupForm(domNode)
