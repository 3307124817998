var $ = require('jquery');

function PricingModal(el) {
  var t = this;

  t.$el = $(el);
  t.$embed = t.$el.find('.pricing-modal__embed');
  t.src = t.$el.data('src');
  t.$iframe = t.$embed.find('iframe');

  if ( ! t.$iframe.length ) {
    t.$iframe = $('<iframe>', {
      src: t.$el.attr('href')
    });
  }

  $(document).on('click', t.$el.data('trigger'), t.click.bind(t));
  t.$el.on('click', '.pricing-modal__overlay, .pricing-modal__close', t.close.bind(t));

}

PricingModal.prototype.close = function () {
  var t = this;
  t.$el.removeClass('js-video-modal-active');
  $('body').removeClass('video-modal-view');
  //t.$iframe.detach();
};

PricingModal.prototype.click = function (e) {
    var t = this;

    e.preventDefault();

    if (t.$iframe[0].src !== e.target.href) {
        t.$iframe[0].src = e.target.href;
    }
    /*
        t.$iframe.appendTo(t.$embed);
    */
  t.$el.addClass('js-video-modal-active');
    $('body').addClass('video-modal-view');

};

module.exports = PricingModal;
