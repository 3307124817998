var $ = require('jquery'),
    sticky = require('../sticky-pin/sticky-pin.js');

function WidgetCategories(el) {

  var $el = $(el),
      $header = $('.header'),
      target;

  $el.find('.widget--categories__item').on('click', function(e) {
    e.preventDefault();
    target = $(this).find('a').attr('href');
    $('html, body').stop().animate({
      'scrollTop': $(target).offset().top - $header.outerHeight() - 20
    }, 400);
  });

  $el.find('.widget--categories__select').on('change', function() {
    target = $(this).val();
    $('html, body').stop().animate({
      'scrollTop': $("#" + target).offset().top - $header.outerHeight() - 60
    }, 400);
  });

}

module.exports = WidgetCategories;
