var $ = require('jquery');
var throttle = require('lodash.throttle');

function Header(el) {
  var $el = $(el),
      $document = $(document),
      $body = $('body'),
      enableFixedHeader = $el.data('fixed-header'),
      $headerSubnav = $el.find('.header__subnav'),
      $blogSearchIcon = $el.find('.header__blog-search-icon');

  $el.find('.header__subnav-current').on('click', function(e) {
    e.preventDefault();
    $headerSubnav.toggleClass('js-subnav-active');
  });

  if( enableFixedHeader !== undefined ) {
    adjustHeader();
    $document.on('scroll', throttle(adjustHeader, 200));
  }

  function adjustHeader() {
    if ($document.scrollTop() > 0) {
      $body.addClass('fixed-header');
    } else {
      $body.removeClass('fixed-header');
    }
  }

  $el.find('.nav-trigger').on('click', function() {
    $('html').toggleClass('js-slideout-active');
  });

  $blogSearchIcon.on('click', function() {
    $el.toggleClass('js-search-active');
  });

  $el.find('.header__blog-categories-label').on('click', function() {
    $el.toggleClass('js-blog-menu-active');
  });

  $el.find('[data-button-scroll]').on('click', function(e) {
    var target = $(this).attr('href');
    if( target.match(/(^http:\/\/)|(^www)/) == null ) {
      e.preventDefault();
      $('html, body').stop().animate({
        'scrollTop': $(target).offset().top - $el.outerHeight() - 20
      }, 400);
    }
  });

}

module.exports = Header;
