const Swiper = require('swiper');

function ThreeUpCarouselFront(el) {
  const containerActiveClass = 'front-testimonials__cards--expanded';

  const expandSwiper = new Swiper (el, {
    slidesPerView: 3,
    watchSlidesVisibility: true,
    centeredSlides: true,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      slideChangeTransitionStart: function () {
        el.classList.remove(containerActiveClass);
      },
    },
    breakpoints: {
      999: {
        // autoHeight: true,
        slidesPerView: 1
      }
    }
  });
}

module.exports = ThreeUpCarouselFront;