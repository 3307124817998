var $ = require('jquery');
var throttle = require('lodash.throttle');

function AlphaGames(el) {
  var $el = $(el);
  $el.find('[data-button-scroll]').on('click', function(e) {
    var target = $(this).attr('href');

    if( target.match(/(^http:\/\/)|(^www)/) == null ) {
      e.preventDefault();
      $('html, body').stop().animate({
        'scrollTop': $(target).offset().top - 120
      }, 400);
    }
  });
}

module.exports = AlphaGames;
