function StripePayment(el) {
    let mutationObserver = new MutationObserver(function(mutations) {
        mutations.forEach(mutation => {
            let mTarget = mutation.target;
            if( hasClass(mTarget, 'alert-error') || hasClass(mTarget, 'alert-success') ) {
                for( var i=0; i<mutation.addedNodes.length; ++i ) {
                    if( mutation.addedNodes[i].textContent === "Could not find payment information" ) {
                        mTarget.innerHTML = "Please enter payment information";
                    }
                    if( mutation.addedNodes[i].textContent === "Payment Successful!" ) {
                        mTarget.innerHTML = "Payment Successful...<br> Thank You!";
                    }
                }
            }
        });
    });

    mutationObserver.observe(el, {
        childList: true,
        subtree: true
    });
}

function hasClass(element, cls) {
    return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
}

module.exports = StripePayment;