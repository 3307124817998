import $ from 'jquery'

const learningObjectiveDetail = $domNode => {
  const $root = $('html, body')
  const $link = $($domNode).find('#lo-detail__games-link')
  const $games = $($domNode).find('#games')

  /**
   * Smooth scroll to games section on click
   */
  $link.click(e => {
    e.preventDefault()
    $root.animate({
      scrollTop: $games.offset().top
    }, 1600)
  })
}

module.exports = learningObjectiveDetail
