const $ = require('jquery')
const _ = require('lodash')

const API_ENDPOINT = process.env.API_ENDPOINT || 'https://app.legendsoflearning.com/api/gameplayer/graphql'
const ASSET_HOST = 'https://res.cloudinary.com/legends/image/upload/'
const HOSTNAME_GAMES = 'https://games.legendsoflearning.com/'
const IMAGE_PARAMS = 'c_scale,w_250'

const makeGameUrl = id => `${HOSTNAME_GAMES}game/${id}`
const makeImageUrl = key => `${ASSET_HOST}${IMAGE_PARAMS}/${key}`
const makeCardHtml = ({ description, estimatedDuration, game, id, image }) => `
  <div class="games-card__card">
    <a href="${makeGameUrl(id)}" target="_blank">
      <img class="games-card__image" src="${makeImageUrl(image)}" alt="Image for ${game}"/>
    </a>
    <div class="games-card__text">
      <span class="games-card__title">${!_.isNil(game) ? game : ''}</span>
      <span class="games-card__estimation">${!_.isNil(estimatedDuration) ? 'Estimated Duration: ' + estimatedDuration + ' minutes' : ''}</span>
      <span class="games-card__description">${!_.isNil(description) ? description : ''}</span>
    </div>
  </div>
`

const gamesCards = $el => {
  const learningObjectiveId = $($el).attr('data-learning-objective-id')
  const gamesQuery = {
    query: `query {
      games (learningObjectiveIds: [${learningObjectiveId}]) {
        entries {
          id
          game
          image
          description
          estimatedDuration
        }          
      }      
    }`
  }

  $.post({
    url: API_ENDPOINT,
    data: gamesQuery
  }).done(({ data }) => {
    const { games } = data

    if (_.isArray(games.entries) && !_.isEmpty(games.entries)) {
      const gamesMarkup = _.map(games.entries, (game) =>
      {
        const id = btoa(JSON.stringify(["games", parseInt(game.id, 10)]))

        const updatedGame = {
          ...game,
          id
        }
        return makeCardHtml(updatedGame)
      }).join('')

      $('.games-cards').append(gamesMarkup)
    } else {
      $('.games-cards').append('Games coming soon!')
    }
  })
}

module.exports = gamesCards
