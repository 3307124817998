var $ = require('jquery')

function faqList (el) {
  const $el = $(el)
  const $item = $el.find('.faq-list__item')

  $item.on('click', '.faq-list__header', function () {
    $(this).closest('.faq-list__item').toggleClass('js-faq-active')
  })
}

module.exports = faqList
