const $ = require('jquery')
const initializeModules = require('./lib/init-modules.js')

$(function () {
  initializeModules()

  if (process.env.NODE_ENV === 'development') {
    console.log('================================================================')
    console.info('App is running in development mode!')
    console.info('Don\'t forget to compile for production by running `yarn build`.')
    console.log('================================================================')
  }
})
