var $ = require('jquery'),
    sticky = require('../sticky-pin/sticky-pin.js');

function TestimonialCategories(el) {

  var $el = $(el),
      $select = $el.find('.testimonial-categories__select');

  $select.on('change', function() {

    var selectText = $(this).find('option[value="' + $(this).val() + '"]').text();

    $('.testimonial-categories__label').text(selectText);

    //hide all columns initially
    $('.testimonial-list__column--show-hide').hide();
    //only show
    $('.testimonial-list__column--show-hide[data-cat-ids*="' + $(this).val() + '"]').show();

    // if value is all (aka 0), show all
    if ($(this).val() === '0') {
      $('.testimonial-list__column--show-hide').show();
    }

  });

}

module.exports = TestimonialCategories;
