var $ = require('jquery');

function VideoModal(el) {
  var t = this;

  t.$el = $(el);
  t.$embed = t.$el.find('.video-modal__embed');
  t.src = t.$el.data('src');
  t.$iframe = t.$embed.find('iframe');

  if ( ! t.$iframe.length ) {
    t.$iframe = $('<iframe>', {
      src: t.$el.attr('href')
    });
  }

  $(document).on('click', t.$el.data('trigger'), t.click.bind(t));
  t.$el.on('click', '.video-modal__overlay, .video-modal__close', t.close.bind(t));
}

VideoModal.prototype.close = function () {
  var t = this;
  t.$el.removeClass('js-video-modal-active');
  t.$iframe.detach();
};

VideoModal.prototype.click = function (e) {
  var t = this;

  e.preventDefault();

  if ( t.$iframe[0].src !== e.target.href ) {
    t.$iframe[0].src = e.target.href;
  }
  t.$iframe.appendTo(t.$embed);
  t.$el.addClass('js-video-modal-active');
};

module.exports = VideoModal;
