import $ from 'jquery'
import _ from 'lodash'

class Modal {
  constructor (domNode, opts = {}) {
    this.$trigger = $(domNode).find('.modal__open')
    this.$overlay = $(domNode).find('.modal__overlay')
    this.$container = $(domNode).find('.modal__container')
    this.$close = $(domNode).find('.modal__close')
    this.onClose = opts.onClose
    this.onOpen = opts.onOpen
    this.open = false
    this.delayMS = 250
    this.init()
  }

  init = () => {
    const onToggle = _.debounce(this.toggleModal, this.delayMS)

    this.$trigger.on('click', onToggle)
    this.$close.on('click', onToggle)
    this.$overlay.on('click', onToggle)
  }

  preventOverflow = isOpen => {
    const overflow = isOpen ? 'auto' : 'hidden'

    $('html').css({ overflow })
  }

  toggleModal = e => {
    if (e) e.preventDefault()
    const { delayMS, isOpen } = this

    if (isOpen) {
      this.preventOverflow(isOpen)
      this.$overlay.stop().fadeOut(delayMS)
      this.$container.stop().fadeOut(delayMS)

      if (_.isFunction(this.onClose)) {
        this.onClose()
      }
    } else {
      this.preventOverflow(isOpen)
      this.$overlay.stop().fadeIn(delayMS)
      this.$container.stop().fadeIn(delayMS)

      if (_.isFunction(this.onOpen)) {
        this.onOpen()
      }
    }

    this.isOpen = !isOpen
  }
}

module.exports = (domNode, opts) => new Modal(domNode, opts)
