var $ = require('jquery');
var Flickity = require('flickity');

function ThreeUpCarousel(el) {
  var $el = $(el);
  var maxHeight = 0;

  // $el.addClass('flex');

  // HACK: Had to put height on div so Flickity would calculate heights correctly.

  setTimeout(function () {

    $el.find('.featured_testimonials__card').each(function() {
      maxHeight = maxHeight > $(this).outerHeight() ? maxHeight : $(this).outerHeight();
    });

    // For mobile
    $el
      .find('.flickity-viewport')
      .height(maxHeight)
      .find('.featured_testimonials__card')
      .height(maxHeight);

    // For desktop
    $el
      .find('.flickity-viewport')
      .height($el.outerHeight())
      .find('.featured_testimonials__card')
      .height($el.outerHeight());

  }, 400);

  // END HACK

  var flkty = new Flickity( el, {
    cellAlign: 'left',
    pageDots: false,
    prevNextButtons: true,
    contain: true,
    setGallerySize: false
  });

}

module.exports = ThreeUpCarousel;
