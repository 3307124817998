const flatpickr = require("flatpickr");
const Choices = require('choices.js');

function DripFormsRestructure(el) {
    const selectsDef = el.querySelectorAll('.choices-js');
    const selectsTop = el.querySelectorAll('.choices-js-top');

    if( selectsDef.length ) {
        const dripSelects = new Choices('.choices-js', {
            removeItems: true,
            removeItemButton: true,
            shouldSort: false,
            searchChoices: false,
            callbackOnCreateTemplates: function (template) {
                let classNames = this.config.classNames;

                return {
                    input: (data) => {
                        return template(`
                            <input type="text" class="${classNames.input} ${classNames.inputCloned}" autocomplete="off" spellcheck="false" readonly>
                        `);
                    }
                }
            }
        });
    }

    if( selectsTop.length ) {
        const dripSelectsTop = new Choices('.choices-js-top', {
            removeItems: true,
            removeItemButton: true,
            shouldSort: false,
            searchChoices: false,
            position: 'top',
            callbackOnCreateTemplates: function (template) {
                let classNames = this.config.classNames;

                return {
                    input: (data) => {
                        return template(`
                            <input type="text" class="${classNames.input} ${classNames.inputCloned}" autocomplete="off" spellcheck="false" readonly>
                        `);
                    }
                }
            }
        });
    }

    const selects = el.querySelectorAll('select');
    if( selects.length ) {
        [].forEach.call(selects, function (item) {
           let selectName = item.getAttribute('data-name');
           let input = document.createElement('input');
           input.setAttribute("type", "hidden");
           input.setAttribute("name", selectName);
            insertAfter(input, item);
           item.addEventListener('change', changeSelect);
        });
    }

    function changeSelect(e) {
        let target= e.target || e.srcElement;
        let dataName = target.getAttribute('data-name');
        let selectedOptions  = [];
        let selectedString = '';

        if( target.options.length ) {
            [].forEach.call(target.options, function (opt) {
                if( opt.selected ) {
                    selectedOptions.push(opt.value);
                }
            });
        }

        selectedString = selectedOptions.join(', ');

        el.querySelector('input[name="' + dataName + '"]' ).setAttribute('value', selectedString);

    }

    function insertAfter(elem, refElem) {
        let parent = refElem.parentNode;
        let next = refElem.nextSibling;
        if (next) {
            return parent.insertBefore(elem, next);
        } else {
            return parent.appendChild(elem);
        }
    }


    flatpickr(".datepicker", {
        dateFormat: "F j, Y",
        minDate: "today",
        "disable": [
            function(date) {
                // return true to disable
                return (date.getDay() === 0 || date.getDay() === 6);

            }
        ],
        "locale": {
            "firstDayOfWeek": 1 // start week on Monday
        }
    });

    flatpickr(".timepicker", {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        minDate: "09:00",
        maxDate: "17:00",
    });

    /*const labels = el.querySelectorAll('label');

    [].forEach.call(labels, function (label) {
        if(label.htmlFor) {
            const fieldID = '#' + label.htmlFor;
            const field = el.querySelector(fieldID);
            if( field && field.type !== 'radio' && field.type !== 'checkbox' ) {
                el.querySelector(fieldID).placeholder = label.innerHTML;
                label.remove();
            }
        }
    });*/
}

module.exports = DripFormsRestructure;