function FrontTestimonialsCard(el) {
  const $card = el;
  const $parent = el.closest('.swiper-slide');
  const $container = el.closest('.front-testimonials__cards');
  const $btn = $card.querySelector('.js-front-testimonials-read-more');

  if(!$btn) return;

  const containerActiveClass = 'front-testimonials__cards--expanded';
  const cardActiveClass = 'front-testimonials-card--expanded';

  $btn.addEventListener('click', testimonialHandle);
  $card.addEventListener('transitionend', testimonialReset);

  function testimonialHandle() {
    if(!$parent.classList.contains('swiper-slide-active')) return;

    if(!$card.classList.contains(cardActiveClass)) {
      testimonialShow();
    } else {
      testimonialHide();
    }
  }

  function testimonialShow() {
    $container.classList.add(containerActiveClass);
    $card.classList.add(cardActiveClass);
  }

  function testimonialHide() {
    $container.classList.remove(containerActiveClass);
    $card.classList.remove(cardActiveClass);
  }

  function testimonialReset() {
    if($card.classList.contains(cardActiveClass)) {
      testimonialHide();
    }
  }

}

module.exports = FrontTestimonialsCard;