const $ = require('jquery')
const Flickity = require('flickity-bg-lazyload')

const libraryCarousel = el => {
  $(el).find('.library-carousel__list').each(function () {
    const options = {
      imagesLoaded: true,
      cellAlign: 'left',
      pageDots: false,
      contain: true,
      groupCells: true,
      bgLazyLoad: 2
    }

    if (matchMedia('screen and (min-width: 750px)').matches) {
      options.bgLazyLoad = 2
    }

    if (matchMedia('screen and (min-width: 1000px)').matches) {
      options.bgLazyLoad = 4
    }

    if (matchMedia('screen and (min-width: 1000px)').matches) {
      options.bgLazyLoad = 5
    }

    $(window).on('load', () => new Flickity(this, options))
  })
}

module.exports = libraryCarousel
